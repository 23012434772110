<template lang="pug">
.main-wrapper.unidades-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / 
                        router-link(:to="{name: 'listarMedEmpresas'}") Empresas 
                        span / 
                        b Aportes

        Dialog.dialog-detalhes(
            :header="'Detalhes Rendimento'" 
            :visible.sync='dialogDetalheAporte' 
            :modal='true'
            @hide="limparFiltros('detalhes')")
            Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
                .p-grid.p-fluid
                    .p-col-12.p-md-4
                        label.form-label Nome:
                        .p-inputgroup
                            InputText(
                                v-model='filters.detalhes.nm_pessoa_fisica'
                                placeholder='Nome do Correntista')
                            Button.p-button-danger(
                                v-if="filters.detalhes.nm_pessoa_fisica" 
                                icon='jam jam-rubber' 
                                @click="filters.detalhes.nm_pessoa_fisica = null")
                    .p-col-12.p-md-2
                        label.form-label(for='cpf') CPF:
                        .p-inputgroup
                            InputMask(
                                v-model='filters.detalhes.nr_cpf'
                                autoClear=false
                                mask='999.999.999-99'
                                placeholder='999.999.999-99')
                            Button.p-button-danger(v-if="filters.detalhes.nr_cpf" icon='jam jam-rubber' @click="filters.detalhes.nr_cpf = null")
                    .p-col-12.p-md-2
                        label.form-label Situação:
                        .p-inputgroup
                            Dropdown(
                                v-model='filters.detalhes.ie_situacao'
                                placeholder='TODAS'
                                :options='options.situacao'
                                optionLabel='label'
                                optionValue='value'
                                dataKey='value'
                            )
                            Button.p-button-danger(
                                v-if="filters.detalhes.ie_situacao"
                                icon='jam jam-rubber'
                                @click="filters.detalhes.ie_situacao = null")
                    .p-col-12.p-md-4
                        label.form-label Pendência:
                            .p-inputgroup
                                SelectButton(
                                    v-model='filters.detalhes.ie_pendente'
                                    placeholder='TODAS'
                                    :options='options.pendencia'
                                    optionLabel='label'
                                    optionValue='value'
                                    dataKey='value'
                                    @input="getDetalhes()")
                    .p-col-12.p-md-8
                    .p-col-12.p-md-2
                        .form-label
                        br
                        Button.p-button-primary(label='Buscar' icon='jam jam-search' @click="getDetalhes()")
                    .p-col-12.p-md-2
                        .form-label
                        br
                        Button.p-button-primary(label='Limpar filtros' icon='jam jam-rubber' @click="limparFiltros('detalhes'); getDetalhes()")
            //- CustomDataTable(:waiting='waiting.detalhes' :header="'Detalhes do Aporte'" :tValue='listaDetalhes' :tData.sync='tables.detalhes' :paginator='paginator.detalhes' @page='onPage')

            Panel.datatable
                template(#header)
                    b Lista de Aportes Individuais
                    Button.selecionados-btn(
                        :disabled='!detalhesSelecionados.length'
                        @click='mostrarSelecionados=!mostrarSelecionados' 
                        v-tooltip.top="mostrarSelecionados ? 'Mostrar Todos' : 'Mostrar Selecionados'"
                        :icon="mostrarSelecionados ? 'fi fi-sr-list' : 'fi fi-sr-list-check'"
                        :class="{\
                            'p-button-default': mostrarSelecionados,\
                            'p-button-success': !mostrarSelecionados\
                        }",
                        :style="{'cursor': detalhesSelecionados.length ? 'pointer' : 'not-allowed'}"
                    )
                    span(v-if="situacaoAporte == 'LAN'")
                        Button.selecionados-btn.p-button-warning.mr-2(
                            :disabled='!detalhesSelecionados.length'
                            icon='fi fi-sr-arrows-cross'
                            v-tooltip.top="'Alternar Situações entre Lançadas e Rejeitadas'"
                            @click='alternarPendencia()'
                            :style="{'cursor': detalhesSelecionados.length ? 'pointer' : 'not-allowed'}"
                        )
                        Button.selecionados-btn.mr-2.gerar-fatura(
                            icon='fi fi-sr-file-invoice-dollar'
                            v-tooltip.top="'Gerar Fatura'"
                            @click='gerarFatura()'
                        )
                            //- :disabled='!detalhesSelecionados.length'
                    //- Button.selecionados-btn.mr-2.consultar-fatura(
                    //-     span(v-if="!['LAN', 'REJ'].includes(situacaoAporte)")
                    //-         icon="fi fi-bs-point-of-sale-bill"
                    //-         v-tooltip.top="'Consultar Fatura'"
                    //-         @click="$router.push(`/agencias/salvar/${ props.data.id }/`)"
                    //-     )
                ProgressBar(v-if='this.waiting.detalhes' mode='indeterminate')
                span(v-else)
                    Paginator.mb-1(
                        :rows='paginator.dinamico.rows' 
                        :first='paginator.dinamico.first' 
                        :totalRecords='paginator.dinamico.total' 
                        @page="paginator.dinamico.onPage")
                    
                    DataTable(
                        :value.sync="listaDinamica" 
                        dataKey='cd_pessoa_fisica'
                        :selection.sync='detalhesSelecionados'                     
                        :expandedRows.sync="expandedRows"
                        responsiveLayout="scroll"
                    )
                        Column(headerStyle='width: 5%;' bodyStyle='text-align:center;' :expander="true")
                            template(#header)
                                i.pi(
                                    @click='expandirTodos' 
                                    style="cursor:pointer"
                                    :class="{\
                                        'pi-chevron-right': expandedRows.length != listaDinamica.length,\
                                        'pi-chevron-down': expandedRows.length == listaDinamica.length\
                                    }")
                        Column(headerStyle='width: 5%' bodyStyle='text-align:center' field='cd_pessoa_fisica')
                            template(#header)
                                Checkbox(v-model='selecionarTodos' binary id='0')
                            template(#body='{data}')
                                Checkbox(v-if="['LAN', 'REJ'].includes(data.ie_situacao)" v-model='detalhesSelecionados' :value='data' :id='data.cd_pessoa_fisica' @click.stop)
                        Column(
                            headerStyle="width: 12%" 
                            bodyStyle='text-align:center; font-size:20px; height:1px; padding: 5px; padding: 0px' 
                            field='ie_pendente' 
                            header='Pendência')
                            template(#body='{data}')
                                span.fi.pendencia(
                                    :class="{'fi-rr-pending': data.ie_pendente,'fi-rr-progress-complete': !data.ie_pendente}"
                                    :style="{'height':'100%', 'backgroundColor': data.ie_pendente ? 'yellow' : 'lightgreen'}"
                                    v-tooltip.tip='options.pendencia[data.ie_pendente]'
                                )
                        Column(bodyStyle='text-align:left' field="nm_pessoa_fisica" header='Correntista'  headerStyle='width: 40%')
                            template(#body='{data}')
                                b {{ data.nm_pessoa_fisica }}
                                br
                                em CPF: {{ $root.formatCPF(data.nr_cpf, true) }}
                                Button.ta-right.p-button-rounded.p-button-warning(
                                    v-if="data.ie_situacao_correntista != 'AT'"
                                    style="float:right; cursor:default" icon='fi fi-br-triangle-warning'
                                    v-tooltip.top="'CORRENTISTA ' + options.situacaoCorrentista[data.ie_situacao_correntista]?.label"
                                )
                        Column(bodyStyle='width: 5%; text-align:center;' field="cd_dependentes" header='Qtd. Dependentes'  headerStyle='width: 18%')
                            template(#body='{data}')
                                span(:style="{\
                                    'position': identificaPendencia(data.cd_dependentes) ? 'absolute' : 'unset',\
                                    'marginTop':identificaPendencia(data.cd_dependentes) ? '1%' : '0',\
                                    }") {{ data.cd_dependentes.length }}
                                br
                                Button.ta-right.p-button-rounded.p-button-warning(
                                    v-if='identificaPendencia(data.cd_dependentes)' 
                                    style="float:right;border:1px solid lightgray; cursor:default"
                                    icon='fi fi-br-triangle-warning'
                                    v-tooltip.top="'DEPENDENTE COM PENDÊNCIA'"
                                )
                                    
                        Column(bodyStyle='text-align:center' field='nr_valor_cobrado' header='Valor Cobrado'  headerStyle='width: 10%')
                            template(#body='{data}')
                                span {{ $root.formatPrice(data.nr_valor_cobrado) }}
                        Column(bodyStyle='text-align:center; height:1px; padding: 1px' field='ie_situacao' header='Situação' headerStyle='width: 10%')
                            template(#body='{data}')
                                span.pendencia(
                                    :style="{'backgroundColor': options.situacaoAporte[data.ie_situacao]?.color}"
                                ) {{ options.situacaoAporte[data.ie_situacao]?.label }}
                        template(#expansion='{data}')
                            DataTable(
                                v-if='data.cd_dependentes.length'
                                :value.sync="data.cd_dependentes"
                                dataKey='cd_pessoa_fisica'
                                responsiveLayout="scroll"
                            )
                                Column(bodyStyle='text-align:left;' field='nm_pessoa_fisica' header='Nome')
                                    template(#body='{data}')
                                        b {{ data.nm_pessoa_fisica }}
                                        br
                                        em(v-if='data.nr_cpf') CPF: {{ $root.formatCPF(data.nr_cpf, true) }}
                                Column(bodyStyle='text-align:center; text-align:center; height:1px; padding: 1px' field='ie_situacao' header='Status')
                                    template(#body='{data}')
                                        span.pendencia(:style="{'backgroundColor': options.situacaoCorrentista[data.ie_situacao]?.color}") {{ options.situacaoCorrentista[data.ie_situacao]?.label }}

                            span.ta-center(v-else style="display:block") Correntista sem Dependentes 

                    Paginator.mb-1(:rows='paginator.dinamico.rows' :first='paginator.dinamico.first' :totalRecords='paginator.dinamico.total' @page="paginator.dinamico.onPage")

        Dialog.dialogApagar(header='Remover Empresa' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover a unidade <b>{{ dialogApagar_data.nm_unidade }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')
                
        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-3
                    label.form-label Competencia:
                        .p-inputgroup
                            Calendar(
                                v-model='filters.aportes.dt_competencia'
                                placeholder='MM/AAAA'
                                dateFormat='mm/yy'
                                view='month'
                                :yearNavigator="true"
                                :yearRange="`2024:${esseAno}`"
                                selectionMode='single'
                                :locale="pt_br"
                                @keydown.enter="getAportes()"
                            )
                            Button.p-button-danger(v-if="filters.aportes.dt_competencia" icon='jam jam-rubber' @click="filters.aportes.dt_competencia = null")

                .p-col-12.p-md-2
                    .form-label Situação:
                    .p-inputgroup
                        Dropdown(
                            v-model='filters.aportes.ie_situacao'
                            placeholder='TODAS'
                            :options='options.situacao'
                            optionLabel='label'
                            optionValue='value'
                            dataKey='value'
                            @keydown.enter="getAportes()"
                        )
                        Button.p-button-danger(v-if="filters.aportes.ie_situacao" icon='jam jam-rubber' @click="filters.aportes.ie_situacao = null")
                .p-col-12.p-md-3
                .p-col-12.p-md-2
                    .form-label
                    br
                    Button.p-button-primary(label='Buscar' icon='jam jam-search' @click='getAportes()')
                .p-col-12.p-md-2
                    .form-label
                    br
                    Button.p-button-primary(label='Limpar filtros' icon='jam jam-rubber' @click="limparFiltros('aportes')")

        CustomDataTable(
            :waiting='waiting.aportes' 
            :header="'Lista de Aportes'" 
            :tValue='listaAportes' 
            :tData.sync='tables.aportes' 
            :paginator='paginator.aportes' 
            @onPage='getAportes($event.page + 1)')
</template>

<style lang="scss">
    .unidades-listar {
        .datatable {
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .dialogSuspender {
            width: 35vw;
        }
        .dialog-detalhes {
            width: 75%;
        }
        .dialog-detalhes {
            .pendencia {
                height: 100% !important;
                display: grid !important;
                align-content: center !important;
            }
            tr.p-highlight {
                .p-row-toggler{
                    color: black !important;
                }
                background-color:#ededed !important;
                color: black !important;
            }
        }
        .p-panel-titlebar {
            align-content: space-between;
            b {
                vertical-align: -webkit-baseline-middle;
            }
            .selecionados-btn {
                float: right;
            }
        }
        .gerar-fatura {
			color: white !important;
			background-color: #6944ed;
			border: 1px solid #6944ed;
			&:hover {
				background-color: #8452ba !important;
				border-color: #8452ba !important;
			}
		}
        .consultar-fatura {
			color: white !important;
			background-color: #c544ed;
			border: 1px solid #c544ed;
			&:hover {
				background-color: #8c31a8 !important;
				border-color: #8c31a8 !important;
			}
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Dropdown from 'primevue/dropdown'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import ProgressSpinner from 'primevue/progressspinner'
    import ToggleButton from 'primevue/togglebutton';
    import SelectButton from 'primevue/selectbutton'
    import { MedEmpresa, DominioValor } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import Calendar from 'primevue/calendar';
    import InputNumber from 'primevue/inputnumber';
    import Checkbox from 'primevue/checkbox';
    import CurrencyInput from "@/components/CustomComponents/CurrencyInput";
    import { pCalendarLocale_ptbr } from './../../utils';
    import Chart from 'primevue/chart';
    import InputSwitch from 'primevue/inputswitch';
    import CustomDataTable from '../CustomComponents/CustomDataTable.vue'
    import * as _ from 'lodash'
    import moment from 'moment' 
    export default {
        created () {
            this.getAportes()
            this.getSitucacoes()
        },
        components: { ProgressBar, DataView, Dropdown, Panel, Paginator, DataTable,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, ToggleButton,
            Calendar, InputNumber, CurrencyInput, Chart, InputSwitch, CustomDataTable,
            InputMask, SelectButton, Checkbox },
        directives: { tooltip: Tooltip },
        computed: {
            selecionarTodos: {
                get() {
                    return (this.mostrarSelecionados 
                        || this.detalhesSelecionados.length )
                        && _.every(this.listaDinamica, (v) => _.find(this.detalhesSelecionados, { cd_pessoa_fisica: v.cd_pessoa_fisica }))
                },
                set(val) {
                    this.detalhesSelecionados = val 
                        ? _.unionBy(this.listaDetalhes, this.detalhesSelecionados, 'cd_pessoa_fisica')
                        : this.mostrarSelecionados 
                            ? [] 
                            : _.xorBy(this.detalhesSelecionados, this.listaDetalhes, 'cd_pessoa_fisica')
                },
            },
            listaDinamica: function () {
                if (this.mostrarSelecionados) {
                    const reagrupa = _.groupBy(
                        this.detalhesSelecionados, 
                        (v) => _.toInteger(_.indexOf(this.detalhesSelecionados, v)/this.paginator.dinamico.rows)
                    )
                    return reagrupa[this.paginator.dinamico.page] 
                } else 
                    return this.listaDetalhes
            },
            selecionaveis: function () {
                return _.filter(this.listaDetalhes, ld => _.includes(['LAN', 'REJ'], ld.ie_situacao))
            }
        },
        watch: {
            detalhesSelecionados: function(val) {
                if (!val.length)
                    this.mostrarSelecionados = false
                else if (this.mostrarSelecionados)
                    this.paginator.dinamico['total'] = val.length
            },
            mostrarSelecionados: function() {
                this.atualizaPaginacao()
            },
        },
        data () {
            return {
                list: [],
                isAdm: false,
                windowInnerWidth: window.innerWidth,
                waitingForm: false,
                waitingCancelar: false,
                waitingApagar: false,
                waitingUtilizacoes: false,
                dialogApagar: false,
                dialogRendimento: false,
                dialogCancelar: false,
                dialogCancelar_id: null,
                waitingToggleStatus: false,
                waitingHistorico: false, 
                dialogApagar_data: {},
                dialogRendimento_data: {},
                dialogSuspender: false,
                dialogDetalheAporte: false,
                filters: {
                    aportes: {
                        cd_empresa: this.$route.params.id,
                        ie_situacao: null,
                        dt_competencia: null
                    },
                    detalhes: {
                        cd_empresa: this.$route.params.id,
                        ie_situacao: null,
                        dt_competencia: null,
                        ie_pendente: null,
                        nm_pessoa_fisica: null,
                        nr_cpf: null,                            
                    }
                },
                options: { 
                    situacao: [
                        {value: null, label: 'TODAS'}
                    ],
                    pendencia: [
                        {value: 'true', label: 'Pendente'},
                        {value: 'false', label: 'Não Pendente'}
                    ],
                    situacaoAporte: {
                        null: { label: 'TODAS' }
                    },
                    situacaoCorrentista: {}
                },
                paginator: {
                    aportes: {
                        page: this.$route.query?.pg || 1,
                        per_page: wsConfigs.paginator_perPage,
                        count: 0
                    },
                    dinamico: {
                        rows: 25,
                        first: 0,
                        total: 0,
                        page: 0,
                        onPage: null
                    },
                    detalhes: {
                        page: 1,
                        per_page: 25,
                        count: 0
                    }
                },
                pt_br: pCalendarLocale_ptbr,
                esseAno: new Date().getUTCFullYear(),
                listaAportes: [],
                listaDetalhes: [],
                expandedRows:  [],
                detalhesSelecionados: [],
                mostrarSelecionados: false,
                situacaoAporte: null,
                waiting: {
                    situacoes: false,
                    aportes: false,
                    detalhes: false
                },
                tables: {
                    aportes: [
                        {
                            header: 'Competência',
                            field: 'dt_competencia_f',
                            bStyle: {
                                textAlign: 'center'
                            }
                        },
                        {
                            header: 'Correntistas',
                            field: 'qtd_correntistas',
                            bStyle: {
                                textAlign: 'center'
                            }
                        },
                        {
                            header: 'Pendentes Inclusão',
                            field: 'qtd_pendentes_inclusao',
                            bStyle: {
                                textAlign: 'center'
                            }
                        },
                        {
                            header: 'Pendentes Exclusão',
                            field: 'qtd_pendentes_exclusao',
                            bStyle: {
                                textAlign: 'center'
                            }
                        },
                        {
                            header: 'Rejeitados',
                            field: 'qtd_rejeitados',
                            bStyle: {
                                textAlign: 'center'
                            }
                        },
                        {
                            header: 'Valor',
                            field: 'nr_valor_total',
                            bStyle: {
                                textAlign: 'center'
                            },
                            body: [
                                {
                                    bType: 'custom',
                                    custom: (data) => this.$root.formatPrice(data.nr_valor_total) 
                                }
                            ]
                        },
                        {
                            header: 'Situação', 
                            field: 'ie_situacao',
                            bStyle: {
                                textAlign: 'center',
                                height:'1px',
                                padding: 0,
                            },
                            body: [
                                {
                                    bType: 'custom',
                                    custom: this.getSituacaoAporte
                                }
                            ]

                        },
                        {header: '', field: '',
                            body: [
                                {
                                    bType: 'button',
                                    icon: 'jam jam-document',
                                    classes: 'p-button-rounded',
                                    tooltip: 'Ver Resumo',
                                    action: this.detalharAporte,
                                }
                            ],
                            bStyle: {
                                textAlign: 'center',
                                padding: 0
                            }
                        },
                    ]
                }
            }
        },
        methods: {
            getAportes (page) {
                let params = {
                    paginacao: true,
                    page: page || this.paginator.aportes.page || 1,
                    per_page: this.paginator.aportes.per_page,
                    ...this.organizaFilters(this.filters.aportes)
                }

                this.waiting.aportes = true
                MedEmpresa.findAportes(params).then(
                    res => {
                        if (res.status === 200) {
                            this.paginator.aportes.count = res.data.count
                            this.listaAportes = res.data.results
                        }
                        this.waiting.aportes = false
                    }
                )
            },
            async getDetalhes(page) {
                this.dialogDetalheAporte = true
                this.waiting.detalhes = true

                const p = page || 1
                this.paginator.dinamico.page = p
                this.paginator.detalhes.page = p

                const params = {
                    paginacao: true,
                    per_page: this.paginator.dinamico.rows,
                    page: p,
                    ...this.organizaFilters(this.filters.detalhes)
                }

                await MedEmpresa.detalharAporte(params).then(res => {
                    if (res.status == 200) {
                        this.mostrarSelecionados = false
                        this.paginator.detalhes.count = res.data.count
                        this.paginator.dinamico.total = res.data.count
                        this.listaDetalhes = res.data.results
                    }
                    this.atualizaPaginacao()
                    this.waiting.detalhes = false
                })
            },
            getSituacaoAporte (data) {
                // const corSituacao = {
                //     LAN: 'lightblue',
                //     REJ: 'indianred'
                // }
                const style = `
                    background-color: ${this.options.situacaoAporte[data.ie_situacao].color};
                    height:100%;
                    align-content:center;
                `
                let situacao = `
                    <div style="${style}">${this.options.situacaoAporte[data.ie_situacao].label}</div>
                `
                
                return situacao
            },
            limparFiltros(filtro) {
                _.each(this.filters[filtro], (val, key) => {
                    switch (true) {
                        case key == 'cd_empresa' || (filtro == 'detalhes' && key == 'dt_competencia'):
                            break;
                        case _.isArray(val):
                            this.filters[filtro][key] = []
                            break;
                        default:
                            this.filters[filtro][key] = null
                            break;
                    }
                })
                this.expandedRows = []
            },
            getSitucacoes () {
                this.waiting.situacoes = true
                Promise.all([
                    DominioValor.findAll({ds_mnemonico: 'SITUACAO_APORTE'}),
                    DominioValor.findAll({ds_mnemonico: 'SITUACAO_CORRENTISTA'})
                ]).then(res => {
                    if (res[0].status == 200) {
                        _.each(res[0].data['SITUACAO_APORTE'].valores, v => {
                            this.options.situacao.push({value: v.ie_valor, label: v.ds_valor})
                            this.options.situacaoAporte[v.ie_valor] = { label: v.ds_valor, color: v.ds_cor }
                        })
                    }
                    if(res[1].status == 200) {
                        _.each(res[1].data['SITUACAO_CORRENTISTA'].valores, v => {
                            this.options.situacaoCorrentista[v.ie_valor] = { label: v.ds_valor, color: v.ds_cor }
                        })
                    }

                    this.waiting.situacoes = false
                })
            },
            detalharAporte (aporte) {
                if (aporte)
                    this.filters.detalhes['dt_competencia'] = moment(aporte.dt_competencia_f, 'MM/YYYY').format('YYYY/MM/DD')
                this.situacaoAporte = aporte.ie_situacao
                this.getDetalhes()
            },
            organizaFilters(filters) {
                return _.transform(filters, (res, val, key) => {
                    if(val){
                        if (key.startsWith('dt_'))
                            res[key] = moment(val).format('YYYY-MM-DD')
                        else if (key == 'nr_cpf') 
                            res[key] = val.replace(/\D/g, '')
                        else 
                            res[key] = val
                    }
                },{})
            },
            onPage(ev) {
                this.getAportes(ev.page + 1)
            },
            onPageDetalhes(ev) {
                console.log("asczxas", ev.page)
                this.paginator.dinamico.page = ev.page + 1
                this.getDetalhes(this.paginator.dinamico.page)
            },
            atualizaPaginacao () {
                this.paginator.dinamico['page'] = this.mostrarSelecionados 
                    ? 0 
                    : this.paginator.detalhes.page || 1;

                [	
                    this.paginator.dinamico['first'],
                    this.paginator.dinamico['total'],
                    this.paginator.dinamico['onPage'],
                ] = this.mostrarSelecionados ? [
                    0,
                    this.detalhesSelecionados.length,
                    ({page}) => {
                        console.log("page: ", page)
                        this.paginator.dinamico.page = page;
                        this.paginator.dinamico.first = this.paginator.dinamico.page * this.paginator.dinamico.rows                        
                    }
                ] : [
                    ((this.paginator.dinamico.page - 1) * this.paginator.dinamico.rows),
                    this.paginator.detalhes.count,
                    this.onPageDetalhes
                ]
            },
            alternarPendencia() {
                this.waiting.detalhes = true
                const ids = {cd_aportes: _.map(this.detalhesSelecionados, 'id')}
                MedEmpresa.atualizarSituacaoAporte(ids).then(async res => {
                    this.waiting.detalhes = false
                    if (res.status == 200){
                        await this.getDetalhes()
                        this.detalhesSelecionados = _.map(this.detalhesSelecionados, ds => {
                            return {...ds, ie_situacao: ds.ie_situacao == 'LAN' ? 'REJ' : 'LAN'}
                        })
                    }
                })
            },
            expandirTodos () {
                this.expandedRows = 
                    _.every(this.listaDinamica, ld => _.find(this.expandedRows, {cd_pessoa_fisica: ld.cd_pessoa_fisica})) 
                        ? [] 
                        : _.uniqBy(_.unionBy(this.expandedRows, this.listaDinamica, 'cd_pessoa_fisica'))
            },
            identificaPendencia (data) {
                data = _.isArray(data) ? data : [data]
                return _.some(data, d => _.includes(['AE', 'AI'], d.ie_situacao))
            },
            gerarFatura() {
                console.log(this.$route.params.id)
                this.waiting.detalhes = true
                MedEmpresa.gerarFatura(this.$route.params.id).then(
                    res => {
                        if (res.status == 200)
                            this.$router.go(0)
                        if (res.status == 400)
                            _.each(res.data, r => this.$toast.error(r))
                        this.waiting.detalhes = false
                    }
                )
            }
        }
    }
</script>	